import React from "react";
import "./sobre.css";

function sobremim() {
  return (
      <div id="sobremim">
        <div className="container-sobre">
          <div>
          </div>
          <div id="desc-me">
            <p className="decricao">
            Olá, sou um estudante do curso de <span>Sistema de Informação</span>. 
            Estou procurando oportunidades para me desenvolver como desenvolvedor e 
            contribuir para projetos interessantes na área de <span>desenvolvimento mobile e web</span>.
            Ao longo do meu curso, tenho buscado <span>desenvolver</span> projetos práticos para aplicar meus conhecimentos e aprimorar minhas habilidades.
            </p>
            <p className="decricao">
            Estou aberto a oportunidades de <span>estágio ou trabalho</span> em projetos que possam me ajudar 
            a crescer como desenvolvedor e agregar valor às empresas e clientes. Sinta-se à vontade 
            para verificar meu perfil no GitHub e entrar em contato comigo se tiver alguma <span>oportunidade</span> interessante.
            </p>
          </div>
        </div>
      </div>
  );
}

export default sobremim;
