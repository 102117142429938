import React from "react";
import "./skills.css";
import Card from "../cards/card";
import ImgCss from "../../images/css.png";
import ImgHtml from "../../images/html.png";
import ImgJs from "../../images/js.png";
import ImgExpo from "../../images/expo.png";
import ImgMongoDB from "../../images/mongoDB.png";
import ImgReact from "../../images/react.png";
import ImgNode from "../../images/node.png";
import ImgFirebase from "../../images/firebase.png";
import ImgReact_Native from '../../images/react_native.png';

function skills() {
  return (
    <>
      <div id="skills">
        <h2 id="skills-title">Skills</h2>

        <div className="container-skills">
          <Card imagem={ImgHtml} titulo={"HTML"} />
          <Card imagem={ImgCss} titulo={"CSS"} />
          <Card imagem={ImgReact} titulo={"ReactJS"} />
          <Card imagem={ImgJs} titulo={"JavaScript"} />
          <Card imagem={ImgReact_Native} titulo={"React Native"} />
          <Card imagem={ImgExpo} titulo={"Expo"} />
          {/* <Card imagem={ImgMongoDB} titulo={"MongoDB"} /> */}
          <Card imagem={ImgNode} titulo={"NodeJs"} />
          <Card imagem={ImgFirebase} titulo={"Firebase"} />
        </div>
      </div>
    </>
  );
}

export default skills;
