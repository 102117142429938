import React from "react";
import CardP from "../cards/card_projeto.jsx";
import "./projetos.css";


import videoReceita from "../../videos/receita.mp4";
import videoCep from "../../videos/cep.mp4";
import videoTarefa from "../../videos/tarefa.mp4";
import videoConversor_moeda from "../../videos/conversor_moeda.mp4";

function projetos() {
  return (
    <>
      <div id="projetos">
        <div className="container-projetos">
          <h2 id="projetos-title">Projetos</h2>

            <div id="div-item">
              <CardP
                video={videoReceita}
                titulo={"Receita"}
                descricao={
                  "O aplicativo Receitas foi desenvolvido em React Native utilizando a plataforma Expo, como parte do evento React Native Insider promovido pelo canal Sujeito Programador no YouTube. O objetivo do aplicativo é fornecer uma maneira fácil de aprender a fazer diversas receitas culinárias."
                }
                linguagem={"React Native, Expo, Firebase, JavaScript"}
                GitHub={"https://github.com/Alessandro021/receitas"}
                apk={"https://www.mediafire.com/file/gbkpwsdax5uh0zi/receitas.apk/file"}

              />

              <CardP
                video={videoTarefa}
                titulo={"Tarefa"}
                descricao={
                  "o app pussui uma parte de login e de cadastro, possui também a área de adicionar tarefas, deletar tarefas e também edição das tarefas já adicionadas."
                }
                linguagem={"React Native, Expo, Firebase, JavaScript"}
                GitHub={"https://github.com/Alessandro021/App-de-tarefas"}
                apk={"https://www.mediafire.com/file/6a4iovs0y9jeu1c/app-de-tarefas_base.apk/file"}
              />

              <CardP
                video={videoCep}
                titulo={"Busca CEP"}
                descricao={
                  "O app de busca de CEP é uma ferramenta prática para quem precisa encontrar endereços a partir do CEP. Utilizando a API ViaCEP, o app permite buscar CEPs de todo o Brasil e visualizar o endereço completo correspondente."
                }
                linguagem={"React Native, Expo"}
                GitHub={"https://github.com/Alessandro021/Buscar-cep"}
                apk={"https://www.mediafire.com/file/woqyt7gz8stw2q0/buscar-cep_base.apk/file"}
              />

              <CardP
                video={videoConversor_moeda}
                titulo={"Conversor de Moeda"}
                descricao={
                  "O app de conversão de moedas é uma ferramenta simples e prática para calcular a conversão entre o real e o dólar. Com ele, você pode converter valores em tempo real e foi utilizado uma API para alimentar o APP."
                }
                linguagem={"React Native, Expo"}
                GitHub={"https://github.com/Alessandro021/Conversor_de_moeda"}
                apk={"https://www.mediafire.com/file/t8htrly9xvx7fke/conversor-de-moedas_base.apk/file"}
              />        
            </div>
        </div>
      </div>
    </>
  );
}

export default projetos;
