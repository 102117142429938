import React from 'react';
import "./bemvindo.css"
import MyFoto from '../../images/my_photo.png';

function bemvindo() {
  return (
      <div id="bemvindo">
          <div className="container-bemvindo">
                <div id='side'>
                  <h1 id='me-title'>Alessandro da Mata</h1>
                  <h5 id='desc'>Desenvolvedor Mobile e Web</h5>
                </div>
                <div ><img id="img" src={MyFoto}/></div>
          </div>
      </div>
  );
}

export default bemvindo;