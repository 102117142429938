import React from 'react';
import "./footer.css";
import {BsGithub} from "react-icons/bs";
import {BsLinkedin} from "react-icons/bs";

function footer() {
  return (
    <>
        <div id="contato">
            <div className="container-footer">
                <div id='redes-contato'>
                    <a href="https://www.linkedin.com/in/alessandro-da-mata-gon%C3%A7alves-4ba89311b" target={"_blank"}> <BsLinkedin/></a>
                    <a href="https://github.com/Alessandro021" target={"_blank"}> <BsGithub/></a>
                </div>

            </div>
        </div>
    </>
  );
}

export default footer;